import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const SemkiTimbildingov = () => {
  return (
    <>
      <Helmet>
        <title>Съемки тимбилдингов в Москве цена от студии Elandic</title>
        <meta name="description" content="Съемка тимбилдингов цена в Москве услуги под ключ от студии Elandic. Заказать услуги профессиональной записи фильма тимбилдинга стоимость от видеопродакшена." />
        <meta name="keywords" content="Съемка тимбилдингов, фильма" />
      </Helmet>
      <MainPage>
        <SeoText title='Съемки тимбилдингов'>
          <p>Хотите запечатлеть незабываемые моменты вашего тимбилдинга, чтобы сохранить воспоминания и мотивировать команду на новые достижения? Студия Elandic предлагает профессиональную съемку тимбилдингов в Москве по доступным ценам!</p>

          <p>Мы понимаем, что каждый тимбилдинг – это уникальное событие, полное эмоций, смеха, командного духа и ярких впечатлений. Наша задача – максимально точно передать атмосферу вашего мероприятия, запечатлев динамику, взаимодействие участников и важные моменты.</p>

          <p>Студия Elandic предлагает комплексный подход к съемке тимбилдингов, включающий в себя профессиональную видеосъемку, фотосъемку, монтаж видео и дополнительные услуги. Мы используем современное оборудование и творческий подход, чтобы создать динамичный и качественный видеоролик, который расскажет историю вашего тимбилдинга, а также сделать яркие и запоминающиеся фотографии, которые передадут атмосферу вашего мероприятия.</p>

          <p>Наши операторы и монтажеры – настоящие профессионалы своего дела, с богатым опытом работы в сфере видео- и фотосъемки. Мы не боимся экспериментировать и предлагаем индивидуальный подход к каждому проекту, учитывая ваши пожелания и специфику вашего тимбилдинга.</p>

          <p>Мы используем современное оборудование и технологии для создания качественного и динамичного видеоконтента. Мы предлагаем гибкую ценовую политику и возможность выбора оптимального варианта съемки в зависимости от ваших потребностей и бюджета. Мы работаем оперативно, чтобы вы могли получить готовый видеоролик или фотографии в максимально короткие сроки.</p>

          <p>Чтобы заказать съемку вашего тимбилдинга, свяжитесь с нами по телефону или электронной почте. Мы с радостью ответим на ваши вопросы, составим индивидуальное предложение и поможем вам запечатлеть яркие моменты вашего мероприятия!</p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(SemkiTimbildingov);
